<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-19 11:14:29
 * @ Description: Fixer's landing index page.
 -->

<template>
    <div
        v-if="platform === 'web'"
    >
        <!-- Navigation -->
        <navigation-landing-component />
        <!-- Navigation -->

        <!-- Router -->
        <transition name="fade">
            <router-view />
        </transition>
        <!-- Router -->
    </div>

    <div
        v-else
        style="min-height: 100vh; background-color: #F9FBFD; display: flex; align-items: center;"
    >
        <landing-native-component />
    </div>
</template>
<script>
    import NavigationLandingComponent from '@/components/navigation/landing/NavigationLandingComponent.vue'
    import LandingNativeComponent from '@/components/landing/native/LandingNativeComponent.vue'
    import { Capacitor } from '@capacitor/core'

    export default {
        name: 'LandingIndexPage',

        components: {
            NavigationLandingComponent,
            LandingNativeComponent
        },

        computed: {
            platform () {
                return Capacitor.getPlatform()
            }
        }
    }
</script>
<style>
    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 1s;
    }

    .fade-leave-active {
        transition: opacity 1s;
        opacity: 0;
    }
</style>
