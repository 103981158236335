<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2022-05-19 10:59:08
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-24 11:21:23
 * @ Description: Fixer's landing page on native mobile apps.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: absolute; top: 20px;"
        >
            <!-- Logo -->
            <v-img
                src="/branding/FixerLogoBlue.png"
                width="140px"
                class="mx-auto"
            />
            <!-- Logo -->
        </v-col>

        <!-- Text -->
        <v-col
            class="mont-40-eb text-center"
            cols="12"
        >
            Welcome
        </v-col>
        <v-col
            cols="12"
            class="rob-16-r text-center"
        >
            Please <span class="rob-16-b">login</span> or <span class="rob-16-b">sign up</span> to effortlessly bring the business world to your mobile.
        </v-col>
        <!-- Text -->

        <!-- Login Button -->
        <v-col
            cols="6"
            class="pr-1"
        >
            <v-btn
                class="white--text no-uppercase rounded-lg rob-18-m"
                color="#2E567A"
                height="43px"
                block
                @click="showLoginOverlay"
            >
                Login
            </v-btn>
        </v-col>
        <!-- Login Button -->

        <!-- Sign Up Button -->
        <v-col
            cols="6"
            class="pl-1"
        >
            <v-btn
                class="white--text no-uppercase rounded-lg rob-18-m"
                color="#FFA858"
                height="43px"
                block
                @click="showSignupOverlay"
            >
                Free Sign Up
            </v-btn>
        </v-col>
        <!-- Sign Up Button -->
    </v-row>
</template>
<script>
    export default {
        name: 'LandingNativeComponent',

        methods: {
            showLoginOverlay () {
                this.$store.commit('setLoginOverlay', true)
            },

            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            }
        }
    }
</script>