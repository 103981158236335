<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 21:15:09
 * @ Description: Component displaying the left app drawer in the landing section on mobiles.
 -->

<template>
    <v-navigation-drawer
        v-model="menuDrawer"
        app
        disable-resize-watcher
        color="white"
        dark
        :width="$vuetify.breakpoint.sm ? '35%' : '60%'"
        class="pa-4"
    >
        <v-row>
            <!-- Join Button -->
            <v-col
                cols="12"
                style="padding-top: 85px;"
                class="text-center"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg rob-18-m"
                    color="#FFA858"
                    style="height: 36px; width: 128px;"
                    @click="showSignupOverlay"
                >
                    Join Fixer
                </v-btn>
            </v-col>
            <!-- Join Button -->

            <!-- Menu Items -->
            <v-col
                cols="12"
                v-for="item in menuItems"
                :key="item.text"
            >
                <a
                    class="pa-0 rob-16-b"
                    style="color: #2E567A;"
                    @click="goToMenuItem(item)"
                >
                    {{ item.text }}
                </a>
            </v-col>
            <!-- Menu Items -->
        </v-row>
    </v-navigation-drawer>
</template>
<script>
    export default {
        name: 'NavigationLandingDrawerMobileLeftMenuComponent',

        props: {
            showMenuDrawer: {
                type: Boolean,
                default: false,
                required: true
            }
        },

        watch: {
            showMenuDrawer: {
                handler (value) {
                    this.menuDrawer = value
                },
                immediate: true
            },

            menuDrawer: {
                handler (value) {
                    this.$emit('toggleMenuDrawer', value)
                }
            }
        },

        data () {
            return {
                menuDrawer: false,
                menuItems: [
                    {
                        text: 'Log In'
                    },
                    {
                        text: 'How It Works',
                        link: 'landing-how-it-works'
                    },
                    {
                        text: 'Solutions',
                        link: 'landing-solutions'
                    },
                    {
                        text: 'Pricing',
                        link: 'landing-pricing'
                    },
                    {
                        text: 'Contact Us'
                    }
                ]
            }
        },

        methods: {
            goToMenuItem (menuItem) {
                if (menuItem.text === 'Log In') {
                    this.showLoginOverlay()
                } else if (menuItem.text !== 'Contact Us' && this.$route.path === '/') {
                    const root = document.getElementById(menuItem.link)
                    root.scrollIntoView({ behavior: 'smooth' })
                } else if (menuItem.text !== 'Contact Us' && this.$route.path !== '/') {
                    if (this.$route.path !== '/') this.$router.push('/')
                } else if (menuItem.text === 'Contact Us' && this.$route.path !== '/contactus') this.$router.push('/contactus')
            },

            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            },

            showLoginOverlay () {
                this.$store.commit('setLoginOverlay', true)
            }
        }
    }
</script>
