<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-08-10 16:59:47
 * @ Description: Component displaying the top nav bar and left side drawer in the landing section.
 -->

<template>
    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="width: 100%; position: absolute; top: 0;"
        v-if="$vuetify.breakpoint.mdAndUp"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
        >
            <v-row
                align="center"
            >
                <!-- Logo -->
                <v-col
                    class="ma-4 ma-lg-6 mr-0 flex-shrink-1 flex-grow-0"
                    style="z-index: 1;"
                >
                    <v-img
                        v-if="($route.path === '/' || $route.name === 'facebookRedirect' || $route.name === 'paymentRedirect')"
                        src="/branding/FixerLogoWhite.png"
                        width="115px"
                        class="mr-auto"
                        style="cursor: pointer;"
                        @click="home()"
                    />
                    <v-img
                        v-else
                        src="/branding/FixerLogoBlue.png"
                        width="115px"
                        class="mr-auto"
                        style="cursor: pointer;"
                        @click="home()"
                    />
                </v-col>
                <!-- Logo -->

                <v-col
                    style="z-index: 5;"
                    class="rob-16-b flex-grow-1 flex-shrink-0"
                    :style="$route.path === '/' || $route.name === 'facebookRedirect' || $route.name === 'paymentRedirect' ? 'color: white;' : 'color: #2E567A;'"
                >
                    <v-row
                        align="center"
                    >
                        <!-- Nav Items -->
                        <ul>
                            <li
                                v-for="(item, index) in topNavItems"
                                :key="index"
                            >
                                <span
                                    :style="$vuetify.breakpoint.lgAndUp ? 'padding-left: 3rem;' : 'padding-left: 1.5rem;'"
                                    style="cursor: pointer;"
                                    @click="goToMenuItem(item)"
                                >
                                    {{ item.text }}
                                </span>
                            </li>
                        </ul>
                        <!-- Nav Items -->

                        <v-col
                            class="text-right"
                        >
                            <!-- Search Button -->
                            <!-- <v-btn
                                icon
                                v-if="$route.path === '/' || $route.name === 'facebookRedirect' || $route.name === 'paymentRedirect'"
                                aria-label="search"
                            >
                                <v-icon
                                    color="white"
                                    class="ml-2 mr-2"
                                >
                                    {{ mdiMagnify }}
                                </v-icon>
                            </v-btn> -->
                            <!-- Search Button -->

                            <!-- Login Button -->
                            <v-btn
                                class="no-uppercase ml-2 mr-2 rob-16-b"
                                :style="$route.path === '/' || $route.name === 'facebookRedirect' || $route.name === 'paymentRedirect' ? 'color: white !important;' : 'color: #2E567A !important;'"
                                text
                                @click="showLoginOverlay"
                            >
                                Login
                            </v-btn>
                            <!-- Login Button -->

                            <!-- Signup Button -->
                            <v-btn
                                class="white--text no-uppercase rounded-lg ml-2 mr-2 rob-16-b"
                                color="#FFA858"
                                style="height: 43px; width: 101px;"
                                @click="showSignupOverlay"
                            >
                                Sign Up
                            </v-btn>
                            <!-- Signup Button -->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-row
        v-else-if="$vuetify.breakpoint.smAndDown"
    >
        <navigation-drawer-mobile-landing-component
            :showMenuDrawer="showMenuDrawer"
            @toggleMenuDrawer="toggleMenuDrawer"
            @showLoginOverlay="showLoginOverlay"
            @showSignupOverlay="showSignupOverlay"
        />

        <v-col
            cols="12"
            style="z-index: 1; background-color: white; position: fixed; top: 0; z-index: 10;"
        >
            <v-row
                align="center"
                class="pa-2"
                style="height: 118px;"
            >
                <!-- Toggle Left Drawer Button -->
                <v-icon
                    color="#2E567A"
                    @click="toggleMenuDrawer(true)"
                    aria-label="menu"
                >
                    {{ mdiMenu }}
                </v-icon>
                <!-- Toggle Left Drawer Button -->

                <!-- <v-col
                    class="text-right pa-0 pr-4 flex-shrink-1 flex-grow-0"
                >
                    <v-img
                        src="/branding/FixerIcon.ico"
                        contain
                        height="48"
                        width="48"
                        class="mx-auto"
                        @click="toggleMenuDrawer(true)"
                        aria-label="menu"
                        style="cursor: pointer;"
                    />
                </v-col> -->

                <!-- Logo -->
                <v-col
                    class="text-right pa-0 px-3 flex-grow-0 flex-shrink-1"
                    v-if="!$store.state.general.customBreakpoints.xxs"
                >
                    <v-img
                        src="/branding/FixerLogoBlue.png"
                        width="100px"
                        class="ml-auto"
                        style="cursor: pointer;"
                        @click="home()"
                    />
                </v-col>
                <!-- Logo -->

                <!-- Signup Button -->
                <v-col
                    class="text-right pa-0 flex-grow-1 flex-shrink-0"
                >
                    <v-btn
                        class="no-uppercase rounded-lg rob-16-b mr-1"
                        style="width: 90px; height: 36px;"
                        color="#2E567A"
                        text
                        @click="showLoginOverlay"
                    >
                        Login
                    </v-btn>
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-16-b"
                        style="width: 90px; height: 36px;"
                        color="#FFA858"
                        @click="showSignupOverlay"
                    >
                        Sign Up
                    </v-btn>
                </v-col>
                <!-- Signup Button -->

                <v-col
                    cols="12"
                    class="pa-0"
                    style="height: 32px;"
                >
                    <div
                        style="width: 100vw; position: fixed; left: 0; top: 68px; display: flex; justify-content: center; align-items: center; font-weight: 400; color: #FFA858; height: 50px; background-color: rgb(252, 241, 230);"
                        color="rgb(252, 241, 230)"
                        :style="$store.state.general.customBreakpoints.xxs ? 'font-size: 14px;' : 'font-size: 18px;'"
                    >
                        DOWNLOAD OUR&nbsp;<span style="font-weight: 700; text-decoration: underline;">APP</span>&nbsp;NOW!
                        <a href="https://play.google.com/store/apps/details?id=coza.fixer.fixer" style="display: flex; align-items: center; justify-content: center;">
                            <img
                                src="/landing/GooglePlay.png"
                                :height="$store.state.general.customBreakpoints.xxs ? 30 : 40"
                                style="cursor: pointer;"
                            />
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->
</template>
<script>
    import { mdiMagnify, mdiMenu } from '@mdi/js'
    import NavigationDrawerMobileLandingComponent from './drawer/mobile/NavigationLandingDrawerMobileLeftMenuComponent.vue'

    export default {
        name: 'NavigationLandingComponent',

        components: {
            NavigationDrawerMobileLandingComponent
        },

        data () {
            return {
                mdiMagnify,
                mdiMenu,
                topNavItems: [
                    {
                        text: 'Find Work'
                    },
                    {
                        text: 'Find Talent'
                    },
                    {
                        text: 'How it Works',
                        link: 'landing-how-it-works'
                    },
                    {
                        text: 'Pricing',
                        link: 'landing-pricing'
                    },
                    {
                        text: 'Contact'
                    }
                ],
                showMenuDrawer: false
            }
        },

        methods: {
            toggleMenuDrawer (value) {
                this.showMenuDrawer = value
            },

            showLoginOverlay () {
                this.$store.commit('setLoginOverlay', true)
            },

            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            },

            goToMenuItem (menuItem) {
                if (menuItem.text === 'Log In') {
                    this.showLoginOverlay()
                } else if (menuItem.text !== 'Contact' && menuItem.text !== 'Find Work' && menuItem.text !== 'Find Talent' && this.$route.path === '/') {
                    const root = document.getElementById(menuItem.link)
                    root.scrollIntoView({ behavior: 'smooth' })
                } else if ((menuItem.text !== 'Contact' && menuItem.text !== 'Find Work' && menuItem.text !== 'Find Talent') && this.$route.path !== '/') {
                    if (this.$route.path !== '/') this.$router.push('/')
                } else if (menuItem.text === 'Contact' && this.$route.path !== '/contactus') this.$router.push('/contactus')
                // else if (menuItem.text !== 'Find Work' && this.$route.path !== '/') {
                //     if (this.$route.path !== '/') this.$router.push('/')
                /* } */ else if (menuItem.text === 'Find Work' && this.$route.path !== '/browse/jobs') this.$router.push('/browse/jobs')
                // else if (menuItem.text !== 'Find Talent' && this.$route.path !== '/') {
                //     if (this.$route.path !== '/') this.$router.push('/')
                /* } */ else if (menuItem.text === 'Find Talent' && this.$route.path !== '/browse/talent') this.$router.push('/browse/talent')
            },

            home () {
                if (this.$route.path !== '/') this.$router.push('/')
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    li span {
        display: block;
    }
</style>